export const environment = {
  production: false,
  region: 'us-east-1',
  baseUrl: 'https://api.stg.ontrax.website/portal',
  settingsBaseUrl: 'https://api.stg.ontrax.website/warehouse',
  searchBaseUrl: 'https://api.stg.ontrax.website/search',
  elasticsearchBaseUrl:
    'https://search-stg-quala-yu3iadwprqjxrbi4ojmdzrn3f4.us-east-1.es.amazonaws.com',
  elasticsearchIndice: 'stg_wash_request',
  elasticsearchIndiceNcr: 'ncr',
  openBravoBaseUrl: 'https://obpreprod.quala.us.com/erp',
  publicAppSyncAPI:
    'https://67j3hy47d5egbog7jvgseln47e.appsync-api.us-east-1.amazonaws.com/graphql',
  amplifyConfig: {
    Auth: {
      identityPoolId: 'us-east-1:11f36642-68c4-4fd5-bd93-8149979d1895',
      userPoolId: 'us-east-1_K9h8KnmA6',
      userPoolWebClientId: '78fisir7hah1suosfr9n8bn2sb',
    },
    API: {
      endpoints: [
        {
          name: 'AuthAPI',
          endpoint: 'https://api.stg.ontrax.website/auth',
        },
        {
          name: 'PortalAPI',
          endpoint: 'https://api.stg.ontrax.website/portal',
        },
        {
          name: 'OnTraxAPI',
          endpoint: 'https://api.stg.ontrax.website/ontrax',
        },
        {
          name: 'WarehouseAPI',
          endpoint: 'https://api.stg.ontrax.website/warehouse',
        },
        {
          name: 'ReportsAPI',
          endpoint: 'https://api.stg.ontrax.website/reports',
        },
        {
          name: 'ShiftLogsAPI',
          endpoint: 'https://api.stg.ontrax.website/shift-logs',
        },
        {
          name: 'SearchAPI',
          endpoint: 'https://api.stg.ontrax.website/search',
        },
        {
          name: 'Elasticsearch',
          endpoint:
            'https://search-stg-quala-yu3iadwprqjxrbi4ojmdzrn3f4.us-east-1.es.amazonaws.com',
          service: 'es',
        },
        {
          name: 'NotificationPreferencesAPI',
          endpoint: 'https://api.stg.ontrax.website/notification-preferences',
        },
        {
          name: 'NonConformityReportAPI',
          endpoint: 'https://api.stg.ontrax.website/ncr',
        },
        {
          name: 'OnTraxAPI',
          endpoint: 'https://api.stg.ontrax.website/ontrax',
        },
        {
          name: 'ExteriorWashOfferAPI',
          endpoint: 'https://api.stg.ontrax.website/exterior-wash-offer',
        },
      ],
    },
    aws_appsync_graphqlEndpoint:
      'https://2sbm5fls7raxnmc75ituc4af7u.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AWS_IAM',
    aws_appsync_apiKey: 'null',
    region: 'us-east-1',
    bucket: 'stg-quala-portal-authenticated-user-upload',
  },
  // Fill after creation of this resource in STG
  systemMonitor: {
    baseUrl: 'https://api.stg.ontrax.website/system',
    apiKeyName: 'stg-quala-system-monitor-api-key',
    apiKeyValue: 'yNffL8MMnE49Kckupwx3m55NH0lsyRog2FgZM93J',
  },
  reports: {
    baseUrl: 'https://api.stg.ontrax.website/reports',
  },
  bulkTemplate: {
    downloadUrl:
      'https://prd-quala-wash-template.s3-us-west-2.amazonaws.com/quala-ontrax-bulk-upload-template.xlsx',
  },
  gtmIdentification: 'GTM-MD3WC49J',
  wikiUrl: 'https://sites.google.com/a/quala.us.com/quala-ontrax-wiki/',
  keycloak: {
    provider: 'loginpreprod.quala.us.com/auth/realms/master',
    issuer: 'https://loginpreprod.quala.us.com/auth/',
    realm: 'master',
    clientId: 'ontrax',
  },
  trainingVideoUrl:
    'https://youtube.com/playlist?list=PLMMT00bdm-ENZStzynM3_KlE1oekuwgWD',
  hotjar: {
    siteId: 3443954,
    version: 6,
  },
  reportsPortal: {
    url: 'https://dev.reports.ontrax.website/',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
